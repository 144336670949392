import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
function App() {
  const [phone, setPhone] = useState('');
  return (
    <div className="App">

      <PhoneInput
        country={'us'}
        enableAreaCodes={true}
        inputClass='input-phone'
        onChange={phone => setPhone(phone) }
      />
      <input type="hidden" id='PhoneNumber' name='PhoneNumber' defaultValue={phone}/>
    </div>
  );
}

export default App;
